<template>
  <custom-misc>
    <span class="icon-page-misc mb-2">
      <AlertCircleIcon />
    </span>

    <div class="text-center mb-1">
      <h2 class="text-center">Página não encontrada</h2>
      <p class="text-center">
        Não foi possível carregar sua requisição.
      </p>
    </div>

    <button
        @click="handleRedirect"
        type="button"
        class="btn btn-misc w-100"
    >
      {{ getMessageButton }}
    </button>
  </custom-misc>
</template>

<script>
import { AlertCircleIcon } from 'vue-feather-icons'
import CustomMisc from '@/views/components/custom/miscellaneous/CustomMisc'
import { isUserLoggedIn } from "@/auth/utils";

export default {
  title: 'Página não encontrada',

  components: {
    CustomMisc,
    AlertCircleIcon,
  },

  methods: {
    handleRedirect() {
      const name = isUserLoggedIn() ? 'home' : 'auth-login'

      this.$router.replace({ name });
    }
  },

  computed: {
    getMessageButton() {
      if(isUserLoggedIn()) {
        return 'Voltar para o início'
      }

      return 'Ir para o login'
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
