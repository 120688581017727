<template>
  <div class="custom-misc">

    <SimpleNavApp />

    <main class="custom-main">
        <div class="page-misc-card">
          <slot />
        </div>
    </main>

    <FooterInitial />

  </div>
</template>

<script>
import SimpleNavApp from "@/views/components/custom/nav-bar/SimpleNavApp"
import FooterInitial from "../footer/FooterInitial.vue"

export default {
  components: {
    SimpleNavApp,
    FooterInitial
},
  data() {
    return {}
  },
  computed: {},
  methods: {},
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
